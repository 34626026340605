import BasePlayScene from '../BaseFinder/PlayScene'

export default class PlayScene extends BasePlayScene {
  preload () {
    this.load.image('bg', '/static/games/game5/bg.jpg');

    if (this.sys.game.device.os.desktop){
      this.load.spritesheet('bg_anim', '/static/games/game5/bg_anim.png',{ frameWidth: 1600, frameHeight: 900 });
    } else {
      this.load.image('bg_anim', '/static/games/game5/bg_noanim.png');
    }
    
    this.load.image('dropzone', '/static/games/game5/dropzone.png');
    this.load.audio('bg_audio', '/static/games/game5/bg.mp3');

    this.corrects = [
      { name:'0.png', x:173, y: 221, drop_x:1187, drop_y:250, width: Math.floor(999/3), height: 98, drop_scale: 0.72,title:"ชุมชนแออัด" },
      { name:'1.png', x:782, y:100, drop_x:1385, drop_y:530, width:  Math.floor(780/3), height: 86, drop_scale: 0.80,title:"หมู่บ้านชาวเผ่าฯ" },
      { name:'2.png', x:902, y:310, drop_x:1503, drop_y:360, width:  Math.ceil(447/3), height: 156, drop_scale: 0.6,title:"สถานบริการทางเพศ" },
      { name:'3.png', x:976, y:594, drop_x:1315, drop_y:488, width: Math.floor(315/3), height: 141, drop_scale: 0.80,title:"Drop in Center" },
      { name:'4.png', x:476, y:192, drop_x:1182, drop_y:375, width:  Math.floor(828/3), height: 131, drop_scale: 0.60,title:"โรงพยาบาล" },
      { name:'5.png', x:582, y:705, drop_x:1341, drop_y:361, width:  Math.floor(546/3), height: 107, drop_scale: 0.88,title:"สวนสาธารณะ" },
      { name:'6.png', x:75, y:683, drop_x:1191, drop_y:490, width:  Math.floor(429/3), height: 144, drop_scale: 0.76,title:"โต๊ะอิหม่าม" },
      { name:'7.png', x:189, y:351, drop_x:1431, drop_y:211, width:  Math.floor(648/3), height: 162, drop_scale: 0.7,title:"สถานบันเทิง" }
    ]

    this.wrongs = [
      { name:'0.png', x:189, y:580, width: Math.floor(564/3), height: 109 },
      { name:'1.png', x:732, y:370, width: Math.floor(210/3), height: 144 },
      { name:'2.png', x:955, y:743, width: Math.floor(216/3), height: 146 },
      { name:'3.png', x:385, y:41, width: Math.floor(573/3), height: 147 },
      { name:'4.png', x:473, y:340, width: Math.floor(645/3), height: 109 },
      { name:'5.png', x:1023, y:315, width: Math.floor(330/3), height: 110 }
    ]

    this.corrects.forEach((item,index)=>{
      this.load.spritesheet('correct-'+index, '/static/games/game5/objects/correct/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    this.wrongs.forEach((item,index)=>{
      this.load.spritesheet('wrong-'+index, '/static/games/game5/objects/wrong/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    super.preload();
  }

  init(data) {
    this.ended = false
    this.timer = 60000
    this.score = 0
    this.total_score = 8
    this.dropzone_w = 429
    this.dropzone_type = 'aside'
  }
}
